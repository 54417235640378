

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  // cursor: default;
  cursor: none;
  user-select: none;
  -webkit-user-drag: none;
}
.info_view{
  opacity: 0;
}
body {
  overflow: hidden;
  background: #3AAFA9;
  color: black;
  height: 100vh;
  margin: 0;
  font-family: "Poppins";
  font-size: 1.5rem;
}

ul{

padding: 0;

}
ul li{

  margin: 0;
  padding-left: 0;
  list-style: none;
  padding-right: 2em;
  display: inline-block;
 


}

a{
  color: rgb(19, 19, 19);
}
.media-footer{
  // background-color: #F2DD8C;
  padding-bottom: 2.5em;
  display: block;
  text-align: center;
}
.icons{
fill: #fff;
  // color: #F2DD8C;
  // background-color: #F2DD8C;
  display: table-cell;
  vertical-align:bottom;
  text-align: center;
  width: 3em;
  height: 2em;
  border-radius: 25px;
  }
.icons:hover{

// color: #F2DD8C;
fill: black;
background-color: #F2DD8C;
display: table-cell;
vertical-align:bottom;
text-align: center;
width: 3em;
height: 2em;
border-radius: 25px;
}


//naviagation bar styling
.cta {
  text-decoration: none;
  color: aliceblue;
  font-size: 1.2rem;
}


@media only screen and (min-width: 600px) {
  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 2em;
    z-index: 10000;
    transition: 0.6s;
  }
  .my-img{
    // padding-top: 3em;
    width: 15em;
    margin-top: 1em;
    margin-right: 12%;
float: right;
  }
  .text2{
    font-size: 3em;
    
    
  }
  .button{
    display: table-cell;
  }
}
@media only screen and (max-width: 600px) {

  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5em;
    // padding: 30px 8%;
    // margin: 0 600px 0 60px;
    z-index: 10000;
    transition: 0.6s;
    &.button{
      display: none;
    }
  }
  .button{
    display: none;
  }
  .my-img{
    width: 8em;
    
   
  }
  .text-area{
    font-size: 1.2rem;
    
  }
  .text2{
    font-size: 3rem;
  }

  .look-btn{
    font-size: 1.2rem;
  }
  
}



.hamburger-container{
  display: table-cell;
  padding-right: 20px;
  
}
.hamburger {
  
  display: block;
  width: 22px;
  height: 11px;
  position: relative;
  span {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 3px;
    background: white;
    z-index: 0;
    &:nth-child(1) {
      top: 0px;
    }
    &:nth-child(2) {
      top: 8px;
    }
    &:before,
    &:after {
      position: absolute;
      content: "";
      display: block;
      top: 0;
      height: 100%;
      width: 0;
      right: 0;
      left: auto;
    }
    &:before {
      z-index: 1;
      background: #F2DD8C;
      transition: all 0.15s linear 0s;
    }
    &:after {
      z-index: 2;
      background: black;
      transition: all 0.15s linear 0.3s;
    }
    &:nth-child(2):before {
      transition-delay: 0.15s;
    }
    &:nth-child(2):after {
      transition-delay: 0.45s;
    }
  }
}
.logo{
  width: 10%;
}

#header-nav:hover{
  .button{
    color: #F2DD8C;
  }
  .hamburger {
    span {
      &:after,
      &:before {
        width: 100%;
        left: 0;
        right: auto;
      }
      &:after {
        z-index: 1;
        transition: all 0.15s linear 0s;
      }
      &:before {
        z-index: 2;
        transition: all 0.15s linear 0.3s;
      }
      &:nth-child(2):after {
        transition-delay: 0.15s;
      }
      &:nth-child(2):before {
        transition-delay: 0.45s;
      }
    }
  }

}



.button {
    // --text: rgb(255, 255, 255);
    --font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    font-size: var(--font-size);
    // color: var(--text);
    
}



.bodysection {
  position: relative;
  padding: 2em 2em 0em 2em;
  height: 100vh;
}


img {

	object-fit: cover;
  transform: scale(1);
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
}

.gallery-wrapper {
  padding: 2rem 0rem;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: auto;
  grid-gap: 1rem;
  grid-auto-flow:dense;
}


.gallery-item {

  width: 100%;
  height: 100%;
  position: relative;
  
}

.gallery-item .image {
  border-radius: 1rem;
  // width: 25%;
  height: 100%;
  overflow: hidden;
}

.gallery-item .image img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  object-position: 50% 50%;
  // cursor: pointer;
  transition: all 400ms ease-in;
  transform: scale(1);
}

.gallery-item .image img:hover {
  transform: scale(1.1);
}

.w-1{
  grid-column: span 1;
}
.w-2{
  grid-column: span 2;
}
.w-3{
  grid-column: span 3;
}
.w-4{
  grid-column: span 4;
}
.w-5{
  grid-column: span 5;
}

.h-1{
  grid-row: span 1;
}
.h-2{
  grid-row: span 2;
}
.h-3{
  grid-row: span 3;
}
.h-4{
  grid-row: span 4;
}
.h-5{
  grid-row: span 5;
}



//cursor styling
.cursor {
  
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background: #F2DD8C;
  position: absolute;
  z-index: 999;
  pointer-events: none;
  box-shadow: 0px 0px 15px 1px #F2DD8C;
  mix-blend-mode: difference;

  &-f {
    width: var(--size);
    height: var(--size);
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='47' height='47' viewBox='0 0 47 47' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M42.4202 42.4202C38.8403 46 33.3594 46 23.5 46C13.6406 46 8.15966 46 4.57983 42.4202C1 38.8403 1 33.3594 1 23.5C1 13.6406 1 8.15966 4.57983 4.57983C8.15966 1 13.6406 1 23.5 1C33.3594 1 38.8403 1 42.4202 4.57983C46 8.15966 46 13.6406 46 23.5C46 33.3594 46 38.8403 42.4202 42.4202Z' stroke='white'/%3E%3C/svg%3E%0A");
    background-size: cover;
    mix-blend-mode: difference;
    pointer-events: none;
    opacity: 0;
  }
}


.loader_container{
  min-height: 100vh;
    background:#3AAFA9 ;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
  --path: #fff;
  --dot: #F2DD8C;
  --duration: 3s;
  width: 44px;
  height: 44px;
  position: relative;
  &:before {
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50%;
      position: absolute;
      display: block;
      background: var(--dot);
      top: 37px;
      left: 19px;
      box-shadow: 0px 0px 15px 1px #F2DD8C;
      transform: translate(-18px, -18px);
      animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  }
  svg {
      display: block;
      width: 100%;
      height: 100%;
      circle {
          fill: none;
          stroke: var(--path);
          // box-shadow: 0px 0px 15px 1px #fff;
          stroke-width: 10px;
          stroke-linejoin: round;
          stroke-linecap: round;
      }

      circle {
          stroke-dasharray: (200 / 4 * 3) (200 / 4) (200 / 4 * 3) (200 / 4);
          stroke-dashoffset: 75;
          animation: pathCircle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
      }
  }
  
}
.text1{
  color: white;
  padding-left: 0.1em;
  height: 0.8em;
  font-weight: 500; 
}
.highlight{
  font-weight: 500;
}
.text2{
  // width: 8em;
  
  height: 1.3em;
  font-weight: bold;
  color:#F2DD8C;
}
.text3{
  color: white;
  padding-left: 0.1em;
  font-weight: 500;
}
.text4{
  width: 40%;
  padding-top: 2em;
  padding-left: 0.1em;
  font-size: 1em;
  font-weight: 500;
}
.highlight{
  color: #F2DD8C;
}
#container{
  
  display: flex;
  gap: 2em;
  padding: 0;
  padding-bottom: 2em;
  margin: 0;
}
.look-btn{
  
  color: black;
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  
  // transition: border-color 2s, background-color 2s;
  :hover{
    background-color: black;
    border-color: transparent;
    // border-radius: %;
    color: white;
    transition: border-color .5s, background-color .5s;
    } 
}
.scroll-downs {
  position: absolute;
  top: 40%;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  
  width :34px;
  height: 55px;
}
.mousey {
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 2px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(.15,.41,.69,.94);
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% { opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(15px); opacity: 0;}
}
.look-btn1{
  
  color: black;
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  
  // transition: border-color 2s, background-color 2s;
  :hover{
    background-color: black;
    border-color: transparent;
    // border-radius: %;
    color: white;
    transition: border-color .5s, background-color .5s;
    } 
}
.achievement-txt{
padding-top: 10em;
font-size: 2em;
font-weight: bold;
}
.fa-btn{
  font-size: 0.7em;
  font-weight: 600;
  letter-spacing: 2px;
  padding: 0.5em 1em 0.5em 1em;
  // display: flex;
  float: left;
  border-radius: 20px;
  border: 2px solid black;
  
}
#top-area{
  padding-top: 4%;
  margin: 0%;

  
}
.text-area{
  padding-top: 5%;
  padding-bottom: 5%;
  // display: flex;
  z-index: 10000;
}


@keyframes dotRect {
  25% {
      transform: translate(0, 0);
  }
  50% {
      transform: translate(18px, -18px);
  }
  75% {
      transform: translate(0, -36px);
  }
  100% {
      transform: translate(-18px, -18px);
  }
}

@keyframes pathCircle {
  25% {
      stroke-dashoffset: 125;
  }
  50% {
      stroke-dashoffset: 175;
  }
  75% {
      stroke-dashoffset: 225;
  }
  100% {
      stroke-dashoffset: 275;
  }
}

.loader {
  display: inline-block;
  margin: 0 16px;
}



